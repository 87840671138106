/* Date range picker styles */
.date-range-picker .react-daterange-picker__calendar--open {
  inset: 100% 0px auto auto !important;
}

.date-range-picker .react-daterange-picker__inputGroup,
.date-range-picker .react-daterange-picker__range-divider,
.date-range-picker .react-daterange-picker__clear-button {
  display: none;
}

.date-range-picker .react-daterange-picker__wrapper {
  border: 0;
}

.date-range-picker .react-calendar {
  border: 0;
  box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.1);
  border-radius: 5px;
  padding-bottom: 10px;
  padding: 10px;
}

.date-range-picker .react-calendar__tile--now,
.date-range-picker .react-calendar__tile--now:hover {
  border: 2px solid #009ef7;
  background: #e3f3fd;
  border-radius: 5px;
}

.date-range-picker .react-calendar__tile--active {
  background: #f9f9f9;
  color: #000;
}

.date-range-picker .react-calendar__tile--rangeStart,
.date-range-picker .react-calendar__tile--rangeEnd,
.date-range-picker .react-calendar__tile--active:hover {
  background: #009ef7;
  color: #fff;
  border-radius: 5px;
}


.date-range-picker .react-daterange-picker__calendar {
  z-index: 1201;
}